import RcTooltip from "rc-tooltip"
import React from "react"
import "rc-tooltip/assets/bootstrap.css"
import "./m-popover.css"
import { isOnScreen } from "src/core/helpers/utils"
function RcPopover(props) {
  const [visible, setVisible] = React.useState(false)
  const { disabled } = props
  if (disabled) {
    return props.children
  }
  if (props.overlay)
    return (
      <RcTooltip
        trigger={"click"}
        placement="top"
        // prefixCls="m-popover"
        visible={visible}
        onVisibleChange={v => {
          setVisible(v)
        }}
        overlayClassName="m-popover"
        onPopupAlign={(node, align) => {
          if (props.autoScrollIntoViewWhenOutOfScreen) {
            if (!isOnScreen(node)) {
              console.warn("onPopupAlign:", node, align, node.offsetTop)
              node.scrollIntoView({ behavior: "smooth" })
            }
          }
        }}
        {...props}
        overlay={() => {
          // console.warn("typeof ", typeof props.overlay)
          if (typeof props.overlay == "function") {
            return props.overlay({
              hide: () => {
                setVisible(false)
              },
              hidePopover: () => {
                setVisible(false)
              },
            })
          }
          return props.overlay
        }}
      >
        {props.children}
      </RcTooltip>
    )
  return props.children
}

export default RcPopover
