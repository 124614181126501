import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"
// const MUI_checkidle_countdown = React.lazy(() =>
//   import("./Types/checkidle_countdown")
// )
import MUI_checkidle_countdown from "./Types/checkidle_countdown"

// const List = {
//   checkidle_countdown: MUI_checkidle_countdown,
// }
const ListNoLazy = {
  checkidle_countdown: MUI_checkidle_countdown,
}
const MyUIOne = {
  init() {
    // MyUIHelpers.addTypesFromListLazy(List)
    //Chuyen sang nolazy de ko bi loi khi mat connection luc bat popup
    MyUIHelpers.addTypesFromListWithoutLazy(ListNoLazy)
  },
}

export default MyUIOne
